import React from 'react'
import Layout from '../components/layout'
import '../styles/privacy_terms.css'

const TermsPage = () => {
  return (
    <Layout footerBackgroundColor={'#F7F9FC'}>
      <section className="Main">
        <h1>Terms of Use</h1>

        <p>Effective date: January 12th 2023</p>
        <p>Welcome to Vitalize. Please read on to learn the rules and restrictions that govern your use of our website(s), platform, including without limitation our Peer Support Chatroom (as defined below), Coaching Session(s), services and mobile application (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</p>
        <p>Email: <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a><br></br>Phone: 434-333-9010<br></br>Address: 800 Indiana Street #460 San Francisco, CA 94107</p>
        <p>These Terms of Use (the “Terms”) are a binding contract between you and <b>VITALIZE CARE, INC.</b> (“Vitalize,” “we” and “us”). Your use of the Services in any way, including without limitation registering for the Services (e.g., creating an account with Vitalize), means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services, provide a Coaching Session (as defined below), or are a registered Coach or user of the Services. These Terms include the provisions in this document as well as those in the Privacy Policy. These Terms apply to all users of the Services, including, without limitation, registered and unregistered users. <b>Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.</b></p>
        <p><b>Please read these Terms carefully.</b> They cover important information about Services provided to you. <b>These Terms include information about</b> <a href="#1">future changes to these Terms</a>, <a href="#15b">limitations of liability</a>, <a href="#15f">a class action waiver and resolution of disputes by arbitration instead of in court</a>. <b>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS ANY OF THE SERVICES IN ANY MANNER.</b></p>
        <p><b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE <a href="#15f">ARBITRATION AGREEMENT SECTION BELOW</a>, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</p>

        <h2 id="1">Will these Terms ever change?</h2>
        <p>We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at <a href="https://vitalizecare.co">https://vitalizecare.co</a>, send you an email, and/or notify you by some other means.</p>
        <p>If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</p>
        <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>

        <h2 id="2">What about my privacy?</h2>
        <p>Vitalize takes the privacy of its users very seriously. For the current Vitalize Privacy Policy, please click <a href="/privacy">here</a>.</p>
        <h3>Children’s Online Privacy Protection Act</h3>
        <p>The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information from children under 13 years of age; if you are a child under 13 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided us personal information, please contact us at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a>.</p>

        <h2 id="3">What are the basics of using Vitalize?</h2>
        <p>Vitalize connects those providing Coaching Session(s) (as defined below) (each a “Coach”) and those wanting to join such Coaching Session(s) (“Users”). When we use the word “you” in these Terms, it refers to any user, regardless of whether they, he or she is a Coach or User, while if we use one of those specific terms, it only applies to that category of user.</p>
        <p>Vitalize uses reasonable efforts to vet each potential Coach before engaging Coaches to provide Coaching Session(s) to Users. However, before receiving health and wellness coaching sessions from any Coach (each a “Coaching Session”), Users are responsible for making their own determinations that the Coach is suitable. Vitalize shall not be responsible for ensuring that information (including credentials) that a User or Coach provides about himself, herself or themselves is accurate or up-to-date. We don’t control the actions of any User or Coach, and Coaches are not our employees.</p>
        <p><b>Coaches are independent contractors of Vitalize and not employees, partners, representatives, agents, joint venturers or franchisees of Vitalize. Vitalize does not employ individuals to perform the Coaching Session(s). You hereby acknowledge that Vitalize does not supervise, direct, control or monitor the Coaching Session(s) and expressly disclaims any responsibility and liability for use of the Coaching Session(s), including but not limited to any warranty or condition of good and workmanlike services, warranty or condition of quality or fitness for a particular purpose, or compliance with any law, regulation, or code.</b></p>

        <h3 id="3a">Creating an Account with Vitalize</h3>
        <p>Although you may be able to visit the website without registering, you will not be able to access or use any other aspect of the Services without registering. You must register and sign up for an account, select a password and user name (“Vitalize User ID”), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You are required to maintain and update your registration data from time to time, to ensure that it is always current, complete and accurate. You may not select as your Vitalize User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</p>
        <p>Additionally, you may be able to access certain parts or features of the Services by using your account credentials from other services (each, a “Third Party Account”), such as those offered by Google. By using the Services through a Third Party Account, you permit us to access certain information from such account for use by the Services. You are ultimately in control of how much information is accessible to us and may exercise such control by adjusting your privacy settings on your Third Party Account.</p>
        <p>When using our Services or using your Vitalize User ID, you represent and warrant that you are an individual of legal age of at least eighteen (18) years old.</p>
        <p>Coaches who provide Coaching Sessions to you have entered into a separate agreements with Vitalize under which they are being compensated.</p>
        <p>You will only use the Services for your own internal, non-commercial, personal use, and not on behalf of or for the benefit of any third party. You will comply with all laws that apply to you, your use of the Services, and your actions and omissions that relate to the Services (for example, Coaches must comply with all laws that relate to the Coaching Session(s)). If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We cannot and will not be responsible for your use of the Services in a way that breaks the law.</p>
        <p>You will not share your Vitalize User ID, account or password with anyone, and you must protect the security of your Vitalize User ID, account, password and any other access tools or credentials. You’re responsible for any activity associated with your Vitalize User ID and account. You agree to inform Vitalize immediately of any unauthorized use of your user account or any other breach of security related to the Services of which you become aware.</p>

        <h3 id="3b">No Medical Advice; Not for Emergencies; Not Healthcare Services</h3>
        <p><b>Vitalize does not offer medical advice or diagnoses, or any other healthcare services, or engage in the practice of medicine, including without limitation, any medical counseling, testing, prescription, procedure or therapy related to the avoidance, prevention, diagnosis or treatment of any acute or chronic illness, disease or condition (collectively, “Healthcare Services”). Our Services are not intended to be a substitute for Healthcare Services provided by licensed healthcare providers, and are instead offered for informational purposes only. The Services are not intended to be, and must not be taken to be, the practice of medicine, nursing, pharmacy or other healthcare advice by Vitalize or any of the Coaches.</b></p>
        <p><b>The Services are not meant to diagnose or treat any conditions – only your medical professional can determine the right course of treatment for you and determine what is safe, appropriate and effective based on your needs. Reliance on any information provided by Vitalize or in connection with the Services or Coaching Sessions is solely at your own risk. You are solely responsible for any decisions or actions you take based on the information and materials available through the Services.</b></p>
        <p><b>You acknowledge that although some of the Coaching Sessions may be provided by individuals in the medical profession, the provision of such Coaching Sessions does not create a medical professional/patient relationship between you and Vitalize or between you and any other individual or entity, and does not constitute an opinion, medical advice, or diagnosis or treatment.</b> The Services, including without limitation the Coaching Session(s), and Content are for general informational purposes only. Healthcare providers and patients should always obtain applicable diagnostic information from appropriate trusted sources. Healthcare providers should never withhold professional medical advice or delay in providing it because of something they have read in connection with our Services.</p>
        <p><b>THE SERVICES SHOULD NEVER BE USED AS A SUBSTITUTE FOR EMERGENCY CARE. IF YOU HAVE A MEDICAL OR MENTAL HEALTH EMERGENCY, ARE THINKING ABOUT SUICIDE OR TAKING ACTIONS THAT MAY CAUSE HARM TO YOU OR TO OTHERS, YOU SHOULD SEEK EMERGENCY TREATMENT AT THE NEAREST EMERGENCY ROOM OR DIAL 911.</b></p>
        <p>The Services, including without limitation the Coaching Session(s), are intended only as a tool. As a result of its limited role as an intermediary between you and Coaches, Vitalize has no control over the appropriateness, accuracy, quality, timeliness, scope, or risks of or associated with any Coaching Session. Therefore, you agree to release Vitalize of any and all claims, demands, damages and liabilities, of any type and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with Services and Coaching Sessions.</p>

        <h2 id="4">What about messaging?</h2>
        <p>As part of the Services, you may receive communications through the Services, including messages that Vitalize sends you (for example, via email or SMS). <b>By signing up for the Services and providing us with your email address and wireless number, you confirm that you want Vitalize to send you information regarding your account or transactions with us, which may include Vitalize using automated dialing technology to text you at the wireless number you provided, and you agree and consent to receive communications from Vitalize, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number and email address has consented to receive communications from Vitalize.</b> You agree to indemnify and hold Vitalize harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing. You agree and understand that the foregoing consent is not a condition to use the Services, including without limitation the Coaching Session(s), and you are not required to provide your phone number or such consent in order to use the Services.</p>
        <p>To the extent you voluntarily opt to have SMS notifications sent directly to your wireless phone number, the following terms apply:</p>
        <ol type="a">
          <li>Your mobile provider’s standard messaging rates apply to our confirmation SMS and all subsequent SMS correspondence. You may choose to receive our mobile alerts by signing up. Message and data rates may apply, according to your individual rate plan provided by your wireless carrier. Please consult your mobile service carrier’s pricing plan to determine the charges for browsing data and sending and receiving text messages. Under no circumstances will we be responsible for any text messaging or wireless charges incurred by you or by a person that has access to your wireless device or telephone number. If your carrier does not permit text alerts, you may not receive our text alerts. Pre-paid phones or calling plans may not be supported. You may receive a bounce back message for every message you send to us.</li>
          <li>We will not be liable for any delays in the receipt of any SMS messages, as delivery is subject to effective transmission from your network operator. SMS message services are provided on an AS IS basis.</li>
        </ol>
        <p>Data obtained from you in connection with this SMS service may include your cell phone number, your provider’s name, and the date, time, and content of your messages. We may use this information to contact you and to provide the Services you request from us. We will never share this information with a third party, except as expressly provided in our <a href="/privacy">Privacy Policy</a>.</p>
        <p>You can cancel the SMS service at any time. Just reply “STOP” to our text. After you send the SMS message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again. If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a>.</p>

        <h2 id="5">Are there restrictions in how I can use the Services?</h2>
        <p>You represent, warrant, and agree that you will not provide or contribute anything, including any Content or User Submission (each of those terms is defined below or above), to the Services, or otherwise use or interact with the Services, in a manner that:</p>
        <ol type="a">
          <li>infringes or violates the intellectual property rights or any other rights of anyone else (including Vitalize);</li>
          <li>violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Vitalize;</li>
          <li>is dangerous, harmful, fraudulent, deceptive,threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
          <li>jeopardizes the security of your Vitalize User ID, password, account or anyone else’s (such as allowing someone else to log in to the Services as you);</li>
          <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
          <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
          <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
          <li>“crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services, including without limitation Coaching Sessions, or Content (through use of manual or automated means);</li>
          <li>copies or stores any significant portion of the Content; or</li>
          <li>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</li>
        </ol>
        <p>In addition, you represent, warrant, and agree that you will not access or use the Services for the purpose of creating or developing, or improving, any services that compete with or will compete with any of the Services.</p>
        <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>

        <h2 id="6">Are there any terms specific to me as a Coach?</h2>
        <p>No Coach may collect any personal information from or relating to Users (“User Information”), whether via the Services, in the course of performing the Coaching Session, or otherwise, beyond what is necessary to perform the Coaching Session for the applicable User from or about whom such User Information was collected. Coaches also must not use any User Information beyond what is necessary to perform the Coaching Sessions for such applicable User. Upon the conclusion of the Coaching Sessions for a User (or otherwise upon the request of such User or Vitalize), Coach must properly destroy all User Information from or relating to such User and make no further use of it whatsoever. Coaches must collect, use, maintain, and transmit all User Information in compliance with all applicable laws.</p>
        <p>All Coaches are subject to Coach Terms and Conditions, available <a href="/coach_terms.pdf">here</a>. For Coaches, if there is a conflict between these Terms of Use and the Coach Terms and Conditions, the Coach Term and Conditions will control to the extent of the conflict.</p>

        <h2 id="7">What are my rights in the Services?</h2>
        <p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, presentations from the Coaching Sessions, User Submissions (as defined below) and so forth provided, or otherwise made accessible on or through the Services (all of the foregoing, the “Content”) are protected by copyright, privacy and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Vitalize’s) rights.</p>
        <p>Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services for such user’s own use is expressly prohibited without prior written permission from us. You understand and agree that Vitalize owns the Services and all intellectual property rights in the Services. For clarity, Coaches owns each of their Coaching Sessions and all intellectual property rights in their Coaching Sessions.  You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</p>

        <h2 id="8">What about anything I contribute to the Services – do I have to grant any licenses to Vitalize or to other users?</h2>
        <h3 id="8a">Peer Support & User Submissions</h3>
        <p>Vitalize may offer community forums where Users can share information and support one another (“Peer Support Chatroom”). Since the Peer Support Chatroom will be open to other Users, it should not be considered private.</p>
        <p>Any information you share in any online community area, including the Peer Support Chatroom, or online discussion during a Coaching Session is by design open to all Users and Coaches, and is therefore not private. You should think carefully before posting any personal or sensitive information in any Peer Support Chatroom, online discussion during a Coaching Session or chat room. What you post can be seen, disclosed to or collected by third parties and may be used by others in ways we cannot control or predict, including to contact you for unauthorized purposes.</p>
        <p>Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission”. Some User Submissions may be viewable by other users. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.</p>
        <p>You agree that you will not post, upload, share, store, or otherwise provide or make available through the Services any User Submissions that: (i) infringe any third party’s copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; (viii) disclose any identifying information about your patients; (ix) disclose any personal health information about yourself or any third party; or (x) that violate any law, including the Health Insurance Portability and Accountability Act.</p>
        <h3 id="8b">Licenses</h3>
        <p>In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our <a href="/privacy">Privacy Policy</a> to the extent they relate to User Submissions that are also your personally-identifiable information.</p>
        <p>By submitting User Submissions through the Services, you hereby do and shall grant Vitalize a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.</p>
        <p>Finally, you understand and agree that Vitalize, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</p>

        <h2 id="9">What if I see something on the Services that infringes my copyright?</h2>
        <p>In accordance with the DMCA, we’ve adopted the following policy toward copyright infringement. We reserve the right to (a) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (b) remove and discontinue service to repeat offenders.</p>
        <ol>
          <li><u>Procedure for Reporting Copyright Infringements.</u>  If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to Vitalize’s Designated Agent to Receive Notification of Claimed Infringement (our “Designated Agent,” whose contact details are listed below):</li>
          <ol type='a'>
            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</li>
            <li>Identification of works or materials being infringed;</li>
            <li>Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Vitalize is capable of finding and verifying its existence;</li>
            <li>Contact information about the notifier including address, telephone number and, if available, email address;</li>
            <li>A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</li>
            <li>A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</li>
          </ol>
          <li><u>Once Proper Bona Fide Infringement Notification is Received by the Designated Agent.</u>  Upon receipt of a proper notice of copyright infringement, we reserve the right to:</li>
          <ol type='a'>
            <li>remove or disable access to the infringing material;</li>
            <li>notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</li>
            <li>terminate such content provider’s access to the Services if he or she is a repeat offender.</li>
          </ol>
          <li><u>Procedure to Supply a Counter-Notice to the Designated Agent.</u>  If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner’s agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:</li>
          <ol type='a'>
            <li>A physical or electronic signature of the content provider;</li>
            <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</li>
            <li>A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</li>
            <li>Content provider’s name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the federal court for the judicial district in which the content provider’s address is located, or, if the content provider’s address is located outside the United States, for any judicial district in which Vitalize is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</li>
          </ol>
        </ol>
        <p>If a counter-notice is received by the Designated Agent, Vitalize may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that Vitalize may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Vitalize’s discretion.</p>
        <p>Please contact Vitalize’s Designated Agent at the following address:</p>
        <p>Vitalize Care, Inc.<br></br>Attn: DMCA Designated Agent<br></br>800 Indiana Street #460 San Francisco, CA 94107</p>

        <h2 id="10">Who is responsible for what I see and do on the Services?</h2>
        <p>Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren’t liable for any errors or omissions or inaccuracies in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services. For clarity, Vitalize does not undertake to authenticate, validate, monitor or moderate any Coaching Session that is supplied by a Coach.</p>
        <p>You are responsible for all Content you contribute, if any, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>
        <p>The Services may contain links or connections to third-party websites or services that are not owned or controlled by Vitalize. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Vitalize is not responsible for such risks.</p>
        <p>Vitalize has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or for any third party that you interact with through the Services, including on the Peer Support Chatroom. In addition, Vitalize will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.</p>
        <p>If there is a dispute between participants on this site or Services, or between users and any third party, you agree that Vitalize is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Vitalize, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. With respect to any and all releases under the Terms, you shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”</p>
        
        <h2 id="11">Will Vitalize ever change the Services?</h2>
        <p>We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>

        <h2 id="12">What if I want to stop using the Services?</h2>
        <p>Users are free to do that at any time by contacting us at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a>; please refer to our <a href="/privacy">Privacy Policy</a>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>
        <p>Vitalize is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. Vitalize has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms; for example, a User who believes that a Coach may be in breach of these Terms is not able to enforce these Terms against that Coach.</p>
        <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</p>
        <p>If you have deleted your account by mistake, contact us immediately at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a> – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.</p>
        <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</p>

        <h2 id="13">What about Mobile Applications?</h2>
        <p>You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an “App Store”). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.</p>

        <h2 id="14">I use the Vitalize App available via the Apple App Store – should I know anything about that?</h2>
        <p>These Terms apply to your use of all the Services, including our iOS applications (the “Application”) available via the Apple, Inc. (“Apple”) App Store, but the following additional terms also apply to the Application:</p>
        <ol type="a">
          <li>Both you and Vitalize acknowledge that the Terms are concluded between you and Vitalize only, and not with Apple, and that Apple is not responsible for the Application or the Content;</li>
          <li>The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</li>
          <li>You will only use the Application in connection with an Apple device that you own or control;</li>
          <li>You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</li>
          <li>In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple’s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</li>
          <li>You acknowledge and agree that Vitalize, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</li>
          <li>You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party’s intellectual property rights, Vitalize, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</li>
          <li>You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</li>
          <li>Both you and Vitalize acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms of agreement which may affect or be affected by such use; and</li>
          <li>Both you and Vitalize acknowledge and agree that Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</li>
        </ol>

        <h2 id="15">What else do I need to know?</h2>
        <p id="15a"><u>Warranty Disclaimer.</u> Vitalize for itself and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Vitalize and all such parties together, the “Vitalize Parties”) make no representations or warranties concerning the Services, including without limitation regarding any Content or Coaching Session contained in or accessed through the Services, and the Vitalize Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits, procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Vitalize Parties make no representations or warranties regarding suggestions or recommendations offered through or in connection with the Services, including any Coaching Sessions. THE SERVICES AND CONTENT ARE PROVIDED BY VITALIZE (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY PARTICULAR RESULTS OR OUTCOME CAN BE ACHIEVED BY THE SERVICES OR COACHING SESSIONS. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <p id="15b"><u>Limitation of Liability.</u> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE VITALIZE PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <p>You agree that Vitalize Parties shall not be responsible or liable for any unauthorized access to, alteration or use of your account, transmissions of data, or for any material or data sent or received or not sent or received through the Services. You agree that Vitalize Parties shall not be responsible or liable for your or any User’s or Coach’s threatening, defamatory, obscene, offensive, or illegal content or conduct or for your or any other User’s or Coach’s violation of another person’s rights, including rights of publicity or privacy rights.</p>
        <p id="15c"><u>Indemnity.</u> To the fullest extent allowed by applicable law, User agrees to indemnify and hold the Vitalize Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), or (b) your violation of these Terms. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses.</p>
        <p id="15d"><u>Assignment.</u> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Vitalize’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
        <p id="15e"><u>Choice of Law.</u> These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of California, without regard to the conflicts of laws provisions thereof.</p>
        <p id="15f"><u>Arbitration Agreement.</u> Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Vitalize and limits the manner in which you can seek relief from Vitalize. Both you and Vitalize acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, Vitalize’s officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</p>
        <ol type='a'>
          <li><i>Arbitration Rules; Applicability of Arbitration Agreement.</i> The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in San Francisco County, California. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.</li>
          <li><i>Costs of Arbitration.</i> The Rules will govern payment of all arbitration fees. Vitalize will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Vitalize will not seek its attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.</li>
          <li><i>Small Claims Court; Infringement.</i> Either you or Vitalize may assert claims, if they qualify, in small claims court in San Francisco County, California or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights.</li>
          <li><i>Waiver of Jury Trial.</i> YOU AND VITALIZE WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Vitalize are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Vitalize over whether to vacate or enforce an arbitration award, YOU AND VITALIZE WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</li>
          <li><i>Waiver of Class or Consolidated Actions.</i> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE COACH OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER COACH OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Vitalize is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below.</li>
          <li><i>Opt-out.</i> You have the right to opt out of the provisions of this section by sending written notice of your decision to opt out to the following address: 800 Indiana Street #460 San Francisco, CA 94107 postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.</li>
          <li><i>Exclusive Venue.</i> If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or Vitalize to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and Vitalize agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in San Francisco County, California.</li>
          <li><i>Severability.</i> If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This Arbitration Agreement will survive the termination of your relationship with Vitalize.</li>
        </ol>
        <p id="15g"><u>Miscellaneous.</u> You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Vitalize may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Vitalize agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Vitalize, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Vitalize, and you do not have any authority of any kind to bind Vitalize in any respect whatsoever.</p>
        <p>Except as expressly set forth in the sections above regarding the Apple Application and the Arbitration Agreement, you and Vitalize agree there are no third-party beneficiaries intended under these Terms.</p>
      </section>
    </Layout>
  )
}

export default TermsPage
